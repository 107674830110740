.order-header {
  background-color: #ffffff;
  border-bottom: none;
  border-bottom: 1px solid #f8f8f9;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.card .order-header h5 {
  font-size: 18px;
  margin-bottom: 0;
  text-transform: capitalize;
  font-weight: 600;
  line-height: 24px;
  color: #222222;
}
.order-header button{
  width: 250px;
}
.filter-container-col{
margin: 10px 0px;
} 
.react-daterange-picker{
  width: 100%;
}
.select_dropdown{
  margin-bottom: 10px;
}