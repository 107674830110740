/* Copyright Information: Created by BuyGroup */
.custom-theme {
  position: fixed;
  right: 0;
  width: 40px;
  height: 38px;
  display: flex;
  z-index: 1;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  text-decoration: none;
  border-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: $grey-about;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(154,154,154,0.54);
  box-shadow: 0px 0px 5px 0px rgba(154,154,154,0.54);
  top: calc(50% + 34px);
  cursor: pointer;
}


// rtl css


body {
  &.rtl {
    direction: rtl;
    .m-r-15 {
      margin-left: 15px;
      margin-right: unset;
    }
    .mr-2, .mx-2 {
      margin-left: 0.5rem !important;
      margin-right: unset !important;
    }
    .custom-select {
      padding: 0.375rem 0.75rem 0.375rem 1.75rem;
    }
    .pl-0, .px-0 {
      padding-right: 0 !important;
      padding-left: unset !important;
    }
    .m-r-10 {
      margin-left: 10px;
      margin-right: unset;
    }
    .radio_animated, .checkbox_animated {
      margin: 0 0 0 1rem;
    }
    .digital-add {
      .form-group {
        .radio_animated {
          margin-left: 8px;
          margin-right: unset;
        }
      }
    }
    .needs-validation {
      .form-group {
        text-align: right;
        .checkbox {
            padding-right: 0;
            padding-left: unset;
        }
      }
      .permission-block {
        .attribute-blocks {
          padding-right: 15px;
          padding-left: unset;
          text-align: right;
          .row {
            padding-right: 20px;
            padding-left: unset;
          }
        }
      }
      .checkbox {
        label {
          padding-right: 16px;
          padding-left: unset;
          &::before {
            right: 0;
            left: unset;
            margin-left: unset;
            margin-right: -16px;
          }
        }
        right: 0;
        margin-left: -16px;
      }
      .radio-animated {
        label {
            margin-left: 20px;
            margin-right: unset;
        }
      }
    }
    .modal-footer {
      > :not(:last-child) {
        margin-left: .25rem;
        margin-right: unset;
      }
    }
    .jsgrid-button {
      +.jsgrid-button {
          margin-right: 5px;
          margin-left: unset;
      }
    }
    .custom-theme {
      left: 0;
      right: unset;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    .text-right {
      text-align: left !important;
    }
    .br-wrapper {
      text-align: right;
    }
    .card {
      .card-header {
        .card-header-right {
          left: 20px;
          right: unset;
          .card-option {
            text-align: left;
            &.list-unstyled {
              padding-right: 0;
              padding-left: unset;
            }
          }
        }
      }
    }
    code[class*=language-], pre[class*=language-] {
      text-align: right;
    }
    .code-box-copy__btn {
      right: unset;
      left: 11px;
    }
    table {
      thead,tbody {
        tr {
          th,td {
            text-align: right;
          }
        }
      }
    }
    .pull-right {
      float: left;
    }
    .status-circle {
      right: 40px;
      left: unset;
    }
    .right-sidebar {
      &.show {
        left: 0;
        right: unset;
      }
      .friend-list-search {
        .fa {
          left: 35px;
          right: unset;
        }
      }
      .chat-box {
        .about {
          float: right;
          padding-right: 10px;
          padding-left: unset;
          text-align: right;
        }
        .friend-list {
          text-align: right;
        }
        .user-image {
          float: right;
          margin-left: 5px;
          margin-right: 0;
        }
      }
    }
    .nav-menus {
      .search-form {
        input {
          padding: 10px 70px 10px 10px;
        }
      }
    }
    ul {
      &.notification-dropdown {
        &.onhover-show-div {
          li {
            text-align: right;
          }
        }
      }
    }
    // sidebar main page
    .page-wrapper {
      .page-body-wrapper {
        .page-sidebar {
          .sidebar-user {
            h6, p {
              text-align: center;
            }
          }
          .sidebar-menu {
            li {
              text-align: right;
               &.active {
                 >.sidebar-submenu {
                   margin-right: 15px;
                   margin-left: unset;
                 }
               }
            }
            .sidebar-submenu {
              >li {
                >a {
                  padding-right: 10px;
                  padding-left: unset;
                  >.fa-circle {
                    right: 0;
                    left: unset;
                  }
                }
              }
            }
            .sidebar-header {
              svg {
                margin-right: unset;
                margin-left: 14px;
              }
            }
          }
          ~ .page-body {
            margin-right: 255px;
            margin-left: unset;
          }
          &.open {
            margin-right: calc(-260px);
            margin-left: unset;
            ~ .page-body, ~ footer {
              margin-right: 0;
            }
          }
        }
        .page-header {
          .row {
            h3 {
              text-align: right;
            }
          }
        }
        footer {
          margin-right: 255px;
          margin-left: unset;
          p {
            i {
              margin-right: 5px;
              margin-left: unset;
            }
          }
        }
      }
    }
    // page main header
    .page-main-header {
      margin-right: 255px;
      margin-left: unset;
      .main-header-right {
        .nav-right {
          text-align: left;
            .profile-dropdown {
              li {
                svg {
                  margin-right: unset;
                  margin-left: 10px;
                }
              }
            }
          > ul {
            > li {
              &:before {
                right: 0;
                left: unset;
              }
              .dot {
                left: 17px;
                right: unset;
              }
              .media {
                .dotted-animation {
                  left: -3px;
                  right: unset;
                  .main-circle {
                    left: 6px;
                    right: unset;
                  }
                  .animate-circle {
                    left: -4px;
                    right: unset;
                  }
                }
              }
              &.onhover-dropdown {
                &:hover {
                  .onhover-show-div {
                    &:before {
                      right: 10px;
                      left: unset;
                    }
                    &:after {
                      right: 10px;
                      left: unset;
                    }
                  }
                }
                &:last-child {
                  padding-left: 0;
                  padding-right: 20px;
                  border-right: none;
                }
              }
              &:first-child {
                border-right: none;
                .search-form {
                  .form-group{
                    &:after {
                      right: 22px;
                      left: unset;
                    }
                    &:before {
                      right: 51px;
                      left: unset;
                    }
                  }
                }
              }
              &:last-child {
                padding-right: 20px;
                padding-left: 0;
              }
              text-align: right;
              i {
                &.ml-2 {
                  margin-right: 0.5rem !important;
                  margin-left: unset !important;
                }
              }
            }
          }
          .language-dropdown {
            text-align: right;
            li {
              a {
                i {
                  margin-left: 10px;
                }
              }
            }
          }
          .profile-dropdown {
            li {
              a {
                svg {
                  margin-right: unset;
                  margin-left: 10px;
                }
              }
            }

          }
        }
        .mobile-sidebar {
          padding-right: 20px;
        }
      }
      &.open {
        margin-right: 0;
      }
    }
    ul {
      &.notification-dropdown {
        &.onhover-show-div {
          right: initial;
          left: -30px;
          padding: 0;
          &:before {
            right: inherit !important;
            left: 35px !important;
          }
          &:after {
            right: inherit !important;
            left: 35px !important;
          }
          li {
            margin-right: 0 !important;
            span {
              &.badge {
                margin-left: unset !important;
              }
              svg {
                margin-right: unset;
                margin-left: 10px;
              }
            }
            .notification-icon {
              margin-left: 20px;
              margin-right: unset;
            }
          }
        }
      }
    }
    .owl-carousel {
      direction: ltr;
    }
    // default page
    .media {
      .media-body {
        text-align: right;
      }
    }
    .order-graph {
      .order-graph-bottom {
        h6 {
          margin-right: 15px !important;
          margin-left: 30px;
        }
      }
    }
    .latest-order-table {
      .btn {
        float: right;
      }
    }
    // product
    .product-physical {
      table {
        tr {
          th, td {
            &:nth-child(2) {
              text-align: right;
            }
          }
        }
      }
    }
    .product-page-details, .product-right {
      .color-variant, .size-box {
        text-align: right;
      }
    }
    .add-product-form {
      .qty-box {
        .input-group {
          .input-group-append {
            .btn {
              border-right: none !important;
              border-left: 1px solid $round-border !important;
            }
          }
          .btn-primary {
            &.bootstrap-touchspin-down {
              border-left: none !important;
              border-right: 1px solid $round-border !important;
            }
          }
        }
      }
      .offset-sm-4 {
        text-align: right;
      }
    }
    .timer {
      float: right;
      ~ .m-t-15 {
        clear: both;
        text-align: right;
        padding-top: 15px;
      }
      span {
        .padding-l {
          right: 35px;
          left: unset;
          padding-right: 0;
        }
      }
    }
    .product-list, .report-table {
      table {
        tr {
          td, th {
            text-align: right !important;
            &:last-child {
              text-align: center !important;
            }
          }
        }
      }
    }
    .dropzone {
      .dz-preview {
        .dz-error-mark, .dz-success-mark {
          left: unset;
          right: 48%;
          margin-right: -27px;
          margin-left: unset;
        }
      }
      &.dz-clickable {
        .dz-message {
          * {
            text-align: center;
          }
        }
      }
    }
    .digital-add {
      .col-form-label {
        display: block;
        text-align: right;
      }
    }
    // datatables
    .dataTables_wrapper {
      .dataTables_filter {
        input[type="search"] {
          margin-right: 10px;
          margin-left: unset;
        }
      }
      table {
        &.dataTable {
          .vendor-list {
            img {
              margin-left: 20px;
              margin-right: unset;
            }
          }
        }
      }
    }
    .category-table {
      table  {
        tr {
          td,th {
            &:nth-child(3) {
              text-align: right;
            }
          }
        }
      }
    }
    .media-table {
      table  {
        tr {
          td,th {
            &:last-child {
              text-align: right !important;
            }
          }
        }
      }
    }
    // profile page
    .profile-details {
      h5 {
        text-align: center;
      }
    }
    .account-setting {
      text-align: right;
    }
    @media only screen and (max-width: 991px) {
      .page-wrapper {
        .page-main-header {
            margin-right: 0;
        }
        .page-body-wrapper {
          .page-sidebar {
            ~ .page-body, ~ footer {
              margin-right: 0;
            }
          }
        }
      }
    }
    @media only screen and (max-width: 767px) {
      .needs-validation {
        .form-group {
          .checkbox {
            padding-right: 15px;
            padding-left: unset;
          }
        }
      }
      .page-wrapper {
        .page-body-wrapper {
          footer {
             p {
               text-align: center;
             }
          }
        }
      }
    }
    @media (min-width: 1200px) {
      .offset-xl-3 {
          margin-right: 25%;
          margin-left: unset;
      }
    }
  }
}
/* Copyright Information: Created by BuyGroup */