/* Copyright Information: Created by BuyGroup */
  /* css for pagination float right */
.react-bootstrap-table-page-btns-ul {
    float: right;
  }
/* css for loading spinner */
.custom-loader {
  text-align: center;
}
/* table sort up down arrow css */
.table .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  float: right;
}
/* table sort up down arrow css */
.table .dropup .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}
a.GroupOrder:hover{
  color: #fff;
  background-color: #9d2730 !important;
    border-color: #9d2730 !important;
}
td.zipcode {
  word-break: break-word;
}
/* group action column */
.group-action{
  justify-content: center;
}
/* forgot password link  */
.forgot-link{
  text-decoration: underline;
  font-size: 14px;
}
/* no customers message */
span.no-data {
  text-align: center;
  padding: 15px;
  background: #ddd;
  margin: 20px;
}
/* disable group edit link */
.disable-link {
  pointer-events: none;
}
.no-next-line{
  white-space:nowrap;
}
.tool-tip-width{

  max-width: 300px;
  overflow:auto
}
.pdf-column{
  width: 120px;
}

input.align-checkbox[type=checkbox]:checked {
  accent-color: #c6313d;
}

.align-checkbox{
  height: 35px;
  width: 35px;
  margin-top: 0;
  margin-left: 0;
}

.display-flex{
  display: flex;
}

.checkbox-size{
  height: 40px;
  width: 50px;
}

.width-100 {
  width: 100%;
}



/* Copyright Information: Created by BuyGroup */