/* Copyright Information: Created by BuyGroup */
.img-responsive {
    max-width: 100%;
}
.main-logo {
    max-height: 60px;
}
.select_dropdown{
    select{
        min-width: 200px;
    }
}

.has-search .form-control {
    padding-left: 2.375rem;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}
.product_listing{
    padding-top: 20px;
    border-bottom: 1px solid rgba(128, 128, 128, 0.11);
    padding-bottom: 20px;
    &:last-child{
        margin-bottom: 0;
        border-bottom : none;
    }
    &:first-child{
        padding-top: 0;
    }
}
.product_thumb{
    img {
        max-height: 45px;
        padding: 6px;
    }
}
.product_info {
    width: 75%;
    .product_detail {
        padding-left: 30px;
    }
}
.product_price{
    strong{
        font-size: 26px;
        color: #c6313d;
    }
}
.rdt_TableHeader{
    min-height: 1px;
}
.rdt_TableHeader {
    display: none;
}

.table_SearchBar {
    position: relative;
    label.search-label {
      margin-bottom: 0;
      display: block;
      max-width: 350px;
      min-width: 260px;
      width: 100%;
      position: relative;
      .form-control{
        padding-right: 40px;
        height: 38px;
        border-color: #ced4da;
      }
      &:after{
        content: "";
        background-image: url("../../assets/images/search.png");
        position: absolute;
        top: 10px;
        right: 15px;
        height: 18px;
        width: 18px;
        background-size: cover;
      }
    }
  
  ::placeholder { 
    color: #7b7b7b;
    opacity: 1; 
  }
  
  :-ms-input-placeholder { 
    color: #7b7b7b;
  }
  
  ::-ms-input-placeholder { 
    color: #7b7b7b;
  }
    
  }
.react-bootstrap-table{
    margin-top: 25px;
    thead{
        background: rgba(224, 224, 224, 0.18);
        font-weight: bold;
        tr{
            th{
                font-weight: bold;
            }
        }
    }
    th.header-classes-description {
        width: 210px;
    }
    th.header-classes-suppliers {
        width: 200px;
    }

    th.header-classes-status_column {
        width: 100px;
    }
    th.header-classes-action {
        width: 50px;
    }

    th.header-classes-customer-name {
        width: 120px;
    }
    th.header-classes-phone {
        width: 110px;
    }
    th.header-classes-address {
        width: 260px;
    }
    th.header-classes-order {
        width: 70px;
    }
    th.header-classes-product-name {
        width: 240px;

    }
    th.header-classes-product-image{
        width: 125px;
    }
    .table-striped tbody tr:nth-of-type(odd) {
        background-color: transparent;
    }
    .table-striped tbody tr:nth-of-type(even) {
        background-color: #FAFAFA;
    }
    .table th {
        vertical-align: top;
        border-bottom: none;
        padding: 13px 0.75rem;
        font-size: 12px;
        border-top: none;
    }
    .table td {
        vertical-align: middle;
        padding: 11px 0.75rem;
        font-weight: normal;
        font-size: 12px;
    }
}
.col-form-label{
    font-weight: bold;
}
.full-calendar .react-datepicker-wrapper {
    display: block;
}
.react-daterange-picker__wrapper{
    height: 38px;
    border: 1px solid #ced4da !important;
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    input{
        color: #222222;;
    }
}
.react-daterange-picker__button svg {
    stroke: #cccccc;
}
.page-item.active {
    .page-link {
        background-color: #c6313d;
        border-color: #c6313d;
        
    }
}
.page-item {
    .page-link {
     &:hover{
        color: #ffffff;
        background-color: #c6313d;
        border-color: #c6313d;
     }   
     &:focus{
         box-shadow: none;
     }
    }
}
.pointer-cursor{
    cursor: pointer;
}
.inline-block{
    display: inline-block;
}

.error{
    color:#c6313d;
    margin-top: 3px;
    
}
.tabular{
    color:black;
    font-weight:'normal'; 
    font-size: 12px;
}
.tabular:hover {
    color:white;
    font-weight:'normal'; 
    font-size: 12px;
    background-color: #c6313d;
}

.div-center{
    text-align: center;
}
body {
    label.err-msg{
        font-size: 14px !important;
        color: red;
        font-weight: 400 !important;
    }
}
.add-product-form .form-group{
    label.err-msg{
        display: block;
    }
}
ul.photo-container.uploaded li {
    width: 100%;
    padding: 10px;
    img{
    height: auto;
    }
}
iframe {
    pointer-events: none;
  }
ul.photo-container {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin-left: -5px;
    margin-right: -5px;
    margin-top: 20px;
    li {
        width: calc(33.33% - 10px);
        border: 1px solid #ddd;
        padding: 0;
        text-align: center;
        margin: 0px 5px;
        background: #f6f6f9;
        margin-bottom: 15px;
        position: relative;
        img {
            width: 100%;
            height: 100px;
            object-fit: contain;
            justify-content: center;
            margin: 0 auto;
            display: block;
            min-width: 100%;
        }
        span {
            position: absolute;
            top: -8px;
            right: -5px;
            cursor: pointer;
            background: #c6313d;
            color: #fff;
            padding: 0px;
            border-radius: 50%;
            height: 20px;
            width: 20px;
        }
    }
}

[type="file"] {
    height: 0;
    overflow: hidden;
    width: 0;
  }
  [type="file"] + label {
    background: #c6313d;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
      font-family: 'Rubik', sans-serif;
      font-size: inherit;
    font-weight: 500;
    margin-bottom: 1rem;
    outline: none;
    padding: 10px 15px;
    position: relative;
    transition: all 0.3s;
    vertical-align: middle;
    width: 100%;
    text-align: center;
    
    &:hover {
      background-color: #9d2730 ;
    }

  }

  body .page-link{
    color: #c6313d;
  }
 body .add-product-form {
    .form-group {
        align-items: self-start;
    }
  }

body .form-control:focus {
    border-color: rgba(198, 49, 61, 0.38);
    box-shadow: 0 0 0 0.2rem rgba(198, 49, 61, 0.11);
}

body .react-confirm-alert-overlay{
    background-color: rgba(0, 0, 0, 0.5);
}
.react-confirm-alert {
    max-width: 500px;
    margin: 1.75rem auto;
    min-width: 500px;
    .custom-ui{
        p{
            margin-bottom: 0;
        }
    }
}



.react-bootstrap-table {
    .table td.reset-expansion-style {
        padding: 0;
        background: rgba(170, 170, 170, 0.1);
        border : 1px solid #dee2e6;
    }
}
.react-bootstrap-table {
    .table {
        td.reset-expansion-style {
            .row-expansion-style {
                padding: 0;
                table {
                    margin: 0;
                }
            }
        }
    }
}
.authentication-box {
    .container {
        .svg-icon {
            padding: 8px;
            display: flex;
            align-items: center;
        }
    }
}
.card {
    .card-header{
        padding: 20px 30px;
    }
}
.parent_slider{
    margin-bottom: 25px;
}
.main_slider_image > img {
    max-height: 300px;
    width: auto;
    margin: 0 auto;
    object-fit: contain;
}
.thumb_slider_image{
    padding: 5px 15px;
    vertical-align: middle;
}
.thumb_slider_image > img {
    max-height: 60px;
    height: 60px;
    width: auto;
    object-fit: contain;
    margin: 0 auto;
}
body .slick-prev {
    left: 0;
}
body .slick-next {
    right: 0;
}
.slick-prev, .slick-next{
    z-index: 9;
}
.select_dropdown {
    li {
        min-width: 260px;
    }
}
.zIndexIncrease {
    .children__menu{
        z-index: 9;
    }
}
.zIndexIncrease.suppliers_dropdown{
    .children__control {
        padding-right: 35px;
    }
}
span.addSupplier {
    position: absolute;
    right: 25px;
    top: 6px;
    background: rgba(220, 48, 48, 0);
    padding: 3px;
    cursor: pointer;
    .fa-plus{
        color: rgb(153, 153, 153);
    }
}
.cu_suneditor{
    position: relative;
    z-index: 1;
}
.list_specification{
    ul{
        list-style-type: circle;
        padding-left: 15px;
        li{
            display: block;
            list-style-type: circle;
            position: relative;
            &:before{
                content: "";
                background: #090909;
                height: 8px;
                width: 8px;
                position: absolute;
                left: -17px;
                top: 6px;
                border-radius: 50%;
            }
        }
    }

    ol{
        list-style-type: circle;
        padding-left: 15px;
        li{
            display: block;
            list-style-type: circle;
            position: relative;
            &:before{
                content: "";
                background: #090909;
                height: 8px;
                width: 8px;
                position: absolute;
                left: -17px;
                top: 6px;
                border-radius: 50%;
            }
        }
    }
    
}
.product_detail_category, .product_detail_value {
    margin-bottom: 15px;
    p{
        color: #313131;
        font-size: 14px;
    }
}

.truncate{
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis; 
}
.show_pdf_name{
    padding: 18px 0px;
}
span.delete_document {
    margin-left: 15px;
    background: #e4566e;
    display: inline-block;
    text-align: center;
    padding: 2px;
    color: #fff;
    border-radius: 50%;
    height: 22px;
    width: 22px;
    vertical-align: middle;
    line-height: 16px;
    cursor: pointer;
}
.expired_parent{
    height: 100vh;
}
.expired_text {
    font-size: 26px;
    color: #333;
}
.page-main-header .main-header-right .nav-right .profile-dropdown{
    width: 170px;
}
.status_column {
    display: inline-block;
    padding: 6px 8px;
    border-radius: 6px;
    min-width: 90px;
    text-align: center;
    font-size: 11px;
    font-weight: bold;
    text-transform: capitalize;
}
.status_column.completed,
.status_column.paid{
    background: rgba(68, 150, 40, 0.1);
    color: #449628;
}
.status_column.shipping {
    background: rgba(243, 155, 62, 0.1);
    color: #f39b3e;
}
.status_column.in-progress {
    background: rgba(198, 49, 61, 0.1);
    color: #c6313d;
}
.status_column.in_progress {
    background: rgba(198, 49, 61, 0.1);
    color: #c6313d;
}
.status_column.Active {
    color: #39801d;
    background: rgba(57, 128, 29, 0.1);
}
.status_column.Upcoming{
    color : #f5bf45;
    background-color: rgba(245, 191, 69, 0.1);
}
.status_column.Closed{
    color: #ff0000;
    background: rgba(255, 0, 0, 0.1);
}

body .modal-title {
    margin-bottom: 0;
    line-height: 1.5;
    font-size: 18px;
    text-transform: capitalize;
    letter-spacing: 0.03em;
    color: #222222;
}

.full_width_date {
    .react-datepicker-wrapper {
        width: 100%;
        display: block;
    }
}
.PhoneInputInput {
   border: 0px;
    border-color: white;
}
.page-wrapper .page-body-wrapper .page-sidebar{
    width: 210px;
}
.page-wrapper .page-body-wrapper .page-sidebar ~ .page-body,
.page-wrapper .page-body-wrapper footer {
    margin-left: 210px;
}
.page-wrapper .page-main-header {
    width: calc(100% - 210px);
    margin-left: 210px;
}
.table-hover tbody {
    .innerSubCategory{
        padding: 0 33px;
        tr:hover{
            background-color: transparent;
        }
    }
}
.list_specification {
    strong {
        font-weight: bold;
        color: #222222;
    }
}
body .react-bootstrap-table {
    table {
        table-layout: auto;
    }
}
.customer_row_repeat.row {
    border-bottom: 1px solid #dee2e6;
    padding: 25px 0;
    &:last-child{
        border-bottom: none;
    }
}
.react-bootstrap-table {
    width: calc(100% + 30px);
    overflow: auto;
    margin: 0 -15px;
    padding: 0 15px;
}
p.no_data_message {
    font-size: 16px;
    color: #222222;
}


@media only screen and (max-width: 1040px) {
    .select_dropdown li {
      min-width: 190px;
    }
    .table_SearchBar label.search-label {
      min-width: 190px;
    }
    .react-bootstrap-table .table th {
        min-width: 90px;
    }
    .react-bootstrap-table th.header-classes-product-name,
    .react-bootstrap-table th.header-classes-description,
    .react-bootstrap-table th.header-classes-product-image {
        width: auto;
    }
  
  }

  @media only screen and (max-width: 990px) {
    body .page-wrapper .page-main-header {
        margin-left: 0;
        width: 100%;
    }
    body .page-wrapper .page-main-header.open {
        width: calc(100% + 210px);
        margin-left: 0;
    }
    .page-wrapper .page-body-wrapper .page-sidebar {
        display: block;
        margin-left: calc(-260px);
    }
    .page-wrapper .page-body-wrapper .page-sidebar.open{
        margin-left: 0px;
    }
    .page-wrapper .page-body-wrapper .page-sidebar ~ .page-body, .page-wrapper .page-body-wrapper footer {
        margin-left: 0;
    }
  }

  .react_slick_thumb_slider .slick-slide {
    padding: 0px 10px;
  }
  .react_slick_thumb_slider .slick-slide > div {
    border: 1px solid rgba(128, 128, 128,  0.1);
    cursor: pointer;
}
.react_slick_main_slider {
    background: rgba(0, 0, 0, 0.01);
}
.export_Button{
    background: #c6313d;
    color: white;
}
.export_Button:hover{
    background-color: #9d2730;
    color:white
}
.zIndexIncrease.disabled_true {
    pointer-events: none;
    opacity: 0.6;
}
span.supplier_name_detail_pro {
    // display: block;
    margin-bottom: 5px;
}
.product_desc {
    overflow: hidden;
    span {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}
.custom-loader.select_loader {
    position: absolute;
    z-index: 9;
    text-align: center;
    width: 100%;
    opacity: 0.7;
    top: 8px;
    .spinner-border {
        width: 1rem;
        height: 1rem;
    }
}
body .__react_component_tooltip {
    background: #000000;
    margin: 0 !important;
    overflow-y: auto;
    overflow-x: hidden;
}
.select_dropdown.productFilterDropdown li {
    min-width: 210px;
}
.email-container{
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    padding-top: 70px;
    width: 100%;
}
.main-content{
    background-image: url("../images/UserVerification/emailbg.jpg");
    background-size: cover;
    width: 600px;
    background-color: #ffffff;
    box-shadow: 0px 3px 6px #cc343233;
    border-radius: 20px;
    margin: auto;
}
.email-container .main-content h1{
    margin-top: 25px;
    padding-top: 15px;
    color:#cc3432;
    margin-bottom: 0;
    background-color: #ffffff;
    text-transform: capitalize;
    font-size:36px;
    display: flex;
    align-items: baseline;
    justify-content: center;

}
.email-container .main-content h1 img{
    margin-left: 10px;
}
.email-container .main-content h4{
    font-weight: 600;
    color:#333333;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 20px;
    padding-top: 5px;
    background-color: #ffffff;
    border-radius: 0 0px 20px 20px;
}
.bottom-text{
    margin: 30px;
    text-align: center;
    font-size: 18px;
    color: #5c5c5c;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
}
.img-effect{
    width:100px;
   padding: 1px 0;
//    background: rgb(248 248 248 / 75%);
   border-radius:20px;
   box-shadow: 5px 10px 15px rgba(0,0,0,0.5);
   animation: mymove 2s linear infinite;
}
.animate{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin:auto;
    animation: MoveUpDown 2s linear infinite;
}
@keyframes animate {
  from { top: 0; left: 0; }
  to   { top: 0px; left: 0px; }
}

@keyframes MoveUpDown {
  0%, 100% {
    bottom: 0;
  }
  50% {
    bottom: 30px;
  }
}
@keyframes mymove {
    0%, 100% {box-shadow:5px 10px 15px rgba(0,0,0,0.5);}
    50% {
          box-shadow: 0px 5px 10px rgba(0,0,0,0.25);
          width: 25px;
        //   background: rgb(248 248 248 / 75%);
    }
}
@media (max-width: 767px) and (min-width: 520px){
    .main-content{
        width: 500px;
    }
}
@media (max-width: 520px) and (min-width: 320px){
    .main-content{
        width: 320px;
    }
    .email-container .main-content h1{
        font-size: 26px;
    }
}
.email-loader{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}
.groupSearchInput {
    position: relative;
    width: 280px;
    border: 1px solid #ced4da;
    border-radius: 3px;
}
span.groupSearch {
    position: absolute;
    top: 8px;
    right: 12px;
}
span.cancelSearch {
    position: absolute;
    top: 8px;
    right: 35px;
    padding-right: 15px;
    font-size: 16px;
    border-right: 1px solid #ddd;
    color: #777;
}
.GroupOrder{
    background: #c6313d;
    color: white;
    border-radius: 3px;
    font-size: 14px;
    padding: 10.5px;
    padding-bottom: 11px;
    border-radius: 5px;
    letter-spacing: 1px;
    line-height: 20px;
    text-transform: uppercase;
    font-weight: 700;
    margin-right: 10px;
    margin-top: 1px;
}
.logoImg {
    max-width: 350px;
    display: flex;
    margin: auto;
    margin-bottom: 20px;
}

.login-page {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(246, 246, 246, 0.60);
    margin-left: -15px;
    margin-right: -15px;
    .container{
        max-width: 400px;
        h3{
            margin-bottom: 20px;
            text-transform: uppercase;
            font-weight: 700;
            margin-top: -5px;
        }
        .lead_login_txt{
            font-size: 14px;
            a{
                text-decoration: underline;
                font-size: 14px;
                color: #c6313d;
            }
        }
        .theme-card{
            padding:30px;
            background-color: #fff;
            box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
            border-radius: 8px;
            border:none;
            display: block;
            .auth-form {
                .form-control {
                    border-radius: 3px;
                    padding: 0px 15px;
                    border: 1px solid #eaeaea;
                    font-size: 14px;
                    height: 44px;
                    line-height: 44px;
                }
                .input-feedback {
                    color: red;
                    font-size: 11px;
                    margin-top: 4px;
                }
                .btn {
                    background: #c6313d;
                    border-color: #c6313d;
                    height: 44px;
                    min-width: 130px;
                    border-radius: 3px;
                    padding: 0px 15px;
                    line-height: 42px;
                }
                .forgot-link {
                    text-align: right;
                    a {
                       
                        text-decoration: underline;
                        font-size: 14px;
                        color: #c6313d;
                    }
                }
                .my_account_footer > .col-md-6 {
                    width: 50%;
                }
            }
            
        }
    }
}

.AddressBox{
    background: #f8f8f8;
    padding: 15px;
    border: 5px;
}
/* Copyright Information: Created by BuyGroup */

